import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../images/logo.png"
import padThai from "../images/padthai.jpg"
import panang from "../images/panang.jpg"
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="top-home">
      <div className="cta">
        <img src={Logo} alt="Thai Village Cuisine" />
        <br />
        <a href="https://www.thaivillagecuisineunion.com/order">
          <button>Online Order</button>
        </a>
      </div>
    </div>
    <section>
      <div className="container">
        <div className="row home-content">
          {/* <div className="col-12 col-md-6">
            <h3>Our Story</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src={padThai} alt="Pad Thai" />
            <img src={panang} alt="Panang" />
          </div> */}
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
